import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import Wave from 'react-wavify';

//images 
import crossstate_img from '../images/kundportal_cross.png';
import kundportal_img from '../images/kundportal.png';
import webservice from "../images/WebserviceTopLogo.png"
function Kundportal() {

    return(
        <Layout>
            <Helmet>
                <title>Customer portal | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="We digitize and automate business processes!" />
                <meta name="keywords" content="Customer portal | log in" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Customer portal" />
                <meta property="og:image" content="We digitize and automate business processes!" />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.crediflow.com/portal" />
                <link rel="cannonical" href="https://www.crediflow.com/portal" />
            </Helmet>

            <section className="bg-gray-100 pt-4">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="bg-kundportal-banner bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden">
                        <div className="w-full h-full" style={{background: "#1d5fb659"}}>
                            <h1 className="pt-36 pb-32  text-4xl lg:text-5xl font-bold text-white text-center">Customer portal</h1><p></p>
                            <Wave 
                                className="-mb-2"
                                fill='#F3F4F6'
                                paused={false}
                                options={{
                                height: 20,
                                amplitude: 50,
                                speed: 0.15,
                                points: 3
                                }}
                            />  
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24 pt-2 pb-32">
                    <div className="w-full grid lg:grid-cols-3 xl:grid-cols-3 gap-20 lg:gap-10">
                        <a href="https://online.crossstate.se/portal/" className="w-full flex flex-col items-center px-4 pb-6 mx-auto bg-white rounded-lg shadow-sm  transition-all transform hover:shadow-2xl hover:scale-105">
                            <div className="bg-white rounded-full p-3 text-gray-200 -mt-10">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-14 h-14" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                </svg>
                            </div>
                            <img src={crossstate_img} alt="crossstate" className="mt-8 h-16"/>
                            <button className="flex items-center justify-center mx-auto mt-10 px-8 py-2 rounded-full  bg-gradient-to-r from-gray-200  to-gray-100 text-gray-700 hover:shadow-md">
                                <span>Web correction</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 inline-block w-7 h-7" fill="currentColor"viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                                    <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                </svg>  
                            </button>
                        </a>
                        <a href="https://app.crossstate.se/RDWeb/" className="w-full flex flex-col items-center px-4 pb-6 mx-auto bg-white rounded-lg shadow-sm  transition-all transform hover:shadow-2xl hover:scale-105">
                            <div className="bg-white rounded-full p-3 text-gray-200 -mt-10">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-14 h-14" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                </svg>
                            </div>
                            <img src={kundportal_img} alt="crossstate" className="mt-8 h-16"/>
                            <button className="flex items-center justify-center mx-auto mt-10 px-8 py-2 rounded-full bg-gradient-to-r from-gray-200  to-gray-100 text-gray-700 hover:shadow-md">
                                <span>Terminal server version</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 inline-block w-7 h-7" fill="currentColor"viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                                    <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                </svg>  
                            </button>
                        </a>
                        <a href="https://webservice.crediflow.se/" className="w-full flex flex-col items-center px-4 pb-6 mx-auto bg-white rounded-lg shadow-sm  transition-all transform hover:shadow-2xl hover:scale-105">
                            <div className="bg-white rounded-full p-3 text-gray-200 -mt-10">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-14 h-14" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                </svg>
                            </div>
                            <img src={webservice} alt="crossstate" className="mt-8 h-18"/>
                            <button className="flex items-center justify-center mx-auto mt-10 px-8 py-2 rounded-full bg-gradient-to-r from-gray-200  to-gray-100 text-gray-700 hover:shadow-md">
                                <span>Web service</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 inline-block w-7 h-7" fill="currentColor"viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                                    <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                </svg>  
                            </button>
                        </a>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Kundportal;